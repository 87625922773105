import { MDXRenderer } from 'gatsby-plugin-mdx';
import { graphql } from 'gatsby';
import * as React from 'react'
import Layout from '../../components/layout'
import Code from '../../components/code';
import 'katex/dist/katex.min.css';
import TeX from '@matejmazur/react-katex';
import { MDXProvider } from '@mdx-js/react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './post.css';

const shortcodes = { TeX, Code };

const BlogPost = ({ data }) => {
  const hero_image = getImage(data.mdx.frontmatter.hero_image)
  return (
    <Layout pageTitle={data.mdx.frontmatter.title}>
      <p>{data.mdx.frontmatter.date}</p>
      <MDXProvider components={shortcodes}>
        <GatsbyImage
          image={hero_image}
          alt={data.mdx.frontmatter.hero_image_alt}
        />
        <p className="photo-credit">
          Photo Credit:{" "}
          <a href={data.mdx.frontmatter.hero_image_credit_link}>
            {data.mdx.frontmatter.hero_image_credit_text}
          </a>
        </p>
        <MDXRenderer>
          {data.mdx.body}
        </MDXRenderer>
      </MDXProvider>
    </Layout>
  )
}

export const query = graphql`
query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        date(formatString: "MMMM D, YYYY")
        title
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
    }
  }
`;

export default BlogPost
